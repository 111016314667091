import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

declare var H: any;

@Injectable({
  providedIn: 'root'
})
export class HereService {

  public platform: any;
  public geocoder: any;

  constructor(private http: HttpClient) {
    this.platform = new H.service.Platform({apikey: ['BZC0T7VH6kx30IvbEr9vShd1dDds5x19mmhK4-Rllp4']});
    console.log('ok');
    this.geocoder = this.platform.getGeocodingService();
  }

  public getAutoComplete(query: string): Observable<any> {
    const url = 'https://autocomplete.search.hereapi.com/v1/autocomplete?apiKey=BZC0T7VH6kx30IvbEr9vShd1dDds5x19mmhK4-Rllp4'
      + '&types=city&q='
      + query + '&lang=fr-FR';
    return this.http.get<any>(url);
  }

  public getAddress(query: string) {
    return new Promise((resolve, reject) => {
      const url = 'https://geocode.search.hereapi.com/v1/geocode?apiKey=BZC0T7VH6kx30IvbEr9vShd1dDds5x19mmhK4-Rllp4'
        + '&types=city&q='
        + query;
      this.http.get<any>(url).subscribe(result => {
        if (result.items.length > 0) {
            resolve(result.items);
        } else {
          reject({message: 'no results found'});
        }
      }, error => {
        reject(error);
      });
    });
  }
}
