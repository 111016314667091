import {APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {throwIfAlreadyLoaded} from './module-import-guard';
import {PokemonService} from './api/pokemon.service';
import {HereService} from './here.service';
// HTTP Interceptors
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptorService} from './auth/auth-interceptor.service';
import {AuthService} from './auth/auth.service';
import {environment} from '../../environments/environment';
import {NgxsModule} from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import {LoggedUserState} from './states/logged-user.state';
import {UsersService} from './api/user.service';
import {LoggedUserService} from './auth/logged-user.service';
import {AuthGuard} from './auth/auth-guard.service';
import {InitAppService} from './init-app.service';

const SERVICE_PROVIDERS = [InitAppService, PokemonService, HereService, AuthService, UsersService, LoggedUserService, AuthGuard, {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptorService,
  multi: true
}];

// Init all services (for store)
export function init_app(appLoadService: InitAppService, authService: AuthService) {
  return () => appLoadService.initStore().catch(err => authService.logout());
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forRoot([LoggedUserState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot(/*{ key: 'app_state' }*/),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ]
})
export class ServicesModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: ServicesModule,
      providers: [
        ...SERVICE_PROVIDERS,
        {
          provide: APP_INITIALIZER,
          useFactory: init_app,
          deps: [InitAppService, AuthService],
          multi: true,
        },
      ],
      deps: [AuthService],
    } as ModuleWithProviders;
  }

  constructor(
    @Optional()
    @SkipSelf()
      parentModule: ServicesModule,
  ) {
    throwIfAlreadyLoaded(parentModule, 'ServicesModule');
  }
}
