import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-pokemon-list-edit',
  templateUrl: './pokemon-list-edit.component.html',
  styleUrls: ['./pokemon-list-edit.component.css']
})
export class PokemonListEditComponent implements OnInit {

  public loading = false;
  public userId: number;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.userId = params.id;
        this.loading = false;
      }
    });

    // Subscribe to its state (to know its connected or not)
    // this.websocketService.state().subscribe((state: StompState) => {
    //   this.state = StompState[state];
    // });
  }

  // onListChanged() {
  //   this.websocketService.send('/topic/refresh', {refresh: this.userId});
  // }

}
