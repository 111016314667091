import {Injectable} from '@angular/core';
import {LoginPayload} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthToken {
  protected accessToken = '';
  protected refreshToken = '';
  protected remember: any = 'false';
  protected data: { UserId?: string } = {};
  protected storageKeys = {
    accessToken: 'auth_app_token',
    refreshToken: 'auth_app_refreshtoken',
    data: 'auth_app_data',
    remember: 'auth_remember_token',
  };

  constructor() {
    this.getAccessToken();
    this.getRefreshToken();
    this.getData();
  }

  public isAuthenticated(): boolean {
    if (this.getAccessToken()) {
      return true;
    }
    return false;
  }

  public setFromPayload(body: LoginPayload & { remember: boolean }) {
    this.setAccessToken(body.accessToken);
    this.setRefreshToken(body.refreshToken);
    this.setData({ UserId: body.userId });
    this.setRemember(body.remember ? 'true' : 'false');
  }

  public setAccessToken(token: string) {
    this.accessToken = token;
    localStorage.setItem(this.storageKeys.accessToken, token);
  }

  public getAccessToken(): string {
    if (!this.accessToken) {
      this.accessToken = localStorage.getItem(this.storageKeys.accessToken);
    }
    return this.accessToken;
  }

  public removeAccessToken() {
    localStorage.removeItem(this.storageKeys.accessToken);
    this.accessToken = '';
  }

  public setRefreshToken(token: string) {
    this.refreshToken = token;
    localStorage.setItem(this.storageKeys.refreshToken, token);
  }

  public getRefreshToken(): string {
    if (!this.refreshToken) {
      this.refreshToken = localStorage.getItem(this.storageKeys.refreshToken);
    }
    return this.refreshToken;
  }

  public removeRefreshToken() {
    localStorage.removeItem(this.storageKeys.refreshToken);
    this.refreshToken = '';
  }

  public getData(): { UserId?: string } {
    if (this.data !== {}) {
      this.data = JSON.parse(localStorage.getItem(this.storageKeys.data)) as { UserId?: string };
    }
    return this.data;
  }

  public setData(data: { UserId?: string }) {
    this.data = data;
    localStorage.setItem(this.storageKeys.data, JSON.stringify(data));
  }

  public setRemember(remember: string) {
    this.remember = remember;
    localStorage.setItem(this.storageKeys.remember, remember);
  }

  public isRemember(): boolean {
    this.remember = localStorage.getItem(this.storageKeys.remember);
    if (this.remember === 'true' || this.remember === true) {
      return true;
    } else {
      return false;
    }
  }

  public clear() {
    Object.values(this.storageKeys).forEach(el => {
      localStorage.removeItem(el);
    });
    this.accessToken = '';
    this.refreshToken = '';
    this.data = {};
  }

}
