export class Pokemon {
  public id: number;
  public userId: number
  public name: string;
  public city: string;
  public pc: number;
  public special: boolean;
  public longitude: number;
  public latitude: number;
}
