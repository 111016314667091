import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Pokemon} from '../../types/pokemon';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PokemonService {

  public static ENDPOINT = '/pokemon';


  constructor(private http: HttpClient) {
  }

  public getPokemonsByUserId(userId: number): Observable<Pokemon[]> {
    const url = this.getAPiUrl() + '/' + userId;
    return this.http.get<Pokemon[]>(url);
  }

  public createPokemon(pokemon: Pokemon): Observable<Pokemon> {
    return this.http.post<Pokemon>(this.getAPiUrl(), pokemon);
  }

  public updatePokemon(pokemon: Pokemon): Observable<Pokemon> {
    return this.http.put<Pokemon>(this.getAPiUrl(), pokemon);
  }

  public deletePokemonById(id: number): Observable<void> {
    const url = this.getAPiUrl() + '/' + id;
    return this.http.delete<void>(url);
  }

  public proceedPokemonExchange(pokemonid1: number, pokemonid2: number) {
    const url = this.getAPiUrl() + '/proceed';
    return this.http.post<void>(url, {pokemonid1, pokemonid2});
  }

  private getAPiUrl(): string {
    return environment.settings.api + PokemonService.ENDPOINT;
  }

}
