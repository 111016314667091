import {Action, Selector, State, StateContext} from '@ngxs/store';
import {User} from '../../types/user';
import {ChangeLoggedUser, ResetLoggedUser} from './logged-user.actions';


export interface LoggedUserStateModel {
  user: User | null;
}

@State<LoggedUserStateModel>({
  name: 'loggedUser',
  defaults: {
    user: null,
  },
})
export class LoggedUserState {
  @Selector()
  public static user(state: LoggedUserStateModel) {
    return state.user;
  }

  @Action(ChangeLoggedUser)
  public changeLoggedUser(ctx: StateContext<LoggedUserStateModel>, action: ChangeLoggedUser) {
    ctx.patchState({
      user: action.user,
    });
  }

  @Action(ResetLoggedUser)
  public resetLoggedUser(ctx: StateContext<LoggedUserStateModel>, action: ResetLoggedUser) {
    ctx.patchState({
      user: null,
    });
  }
}
