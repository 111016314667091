import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatAutocompleteSelectedEvent, MatDialog,
  MatDialogRef,
  MatSnackBar,
  MatSort,
  MatTableDataSource,
  Sort
} from '@angular/material';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Pokemon} from '../types/pokemon';
import {PokemonList} from '../types/pokemonList';
import {PokemonService} from '../services/api/pokemon.service';
import {HereService} from '../services/here.service';


export interface DialogData {
  poke: Pokemon;
}

@Component({
  selector: 'app-pokemon-list',
  templateUrl: './pokemon-list.component.html',
  styleUrls: ['./pokemon-list.component.css']
})
export class PokemonListComponent implements OnInit {

  @Input() userId: number;
  @Input() forEdit: boolean;
  @Output() listChanged = new EventEmitter<Pokemon>();
  @ViewChild('myForm', {static: false}) myForm: NgForm;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public longitude = 0;
  public latitude = 0;
  public pokemons: Pokemon[] = [];
  public pokemonDataSource: MatTableDataSource<Pokemon>;
  public loading = false;
  displayedColumns: string[] = ['id', 'name', 'city', 'pc'];

  pokemonForm = new FormGroup({
    pokemon: new FormControl(''),
    PC: new FormControl(''),
    city: new FormControl(''),
    special: new FormControl(false),
  });

  filteredOptions: Observable<string[]>;
  autocompleteCity: Observable<string[]>;
  autocompleteCityArray: string[] = [];

  constructor(private snackBar: MatSnackBar,
              private pokemonService: PokemonService,
              private here: HereService,
              public dialog: MatDialog) {
  }


  ngOnInit() {
    this.loading = true;
    if (this.forEdit) {
      this.displayedColumns.push('options');
    }
    this.pokemonDataSource = new MatTableDataSource(this.pokemons);
    this.pokemonDataSource.sort = this.sort;
    this.filteredOptions = this.pokemonForm.controls.pokemon.valueChanges
      .pipe(
        startWith(''),
        map(pokename => pokename ? this._filterPokemon(pokename) : PokemonList.slice())
      );
    this.refreshPokemonList();
    this.autocompleteCity = this.pokemonForm.controls.city.valueChanges.pipe(
      map(value => value ? this.autocompleteCityArray : [])
    );

    this.pokemonForm.controls.city.valueChanges.subscribe(value => {
      if (value !== '') {
        this.here.getAutoComplete(value).subscribe(value1 => {
          this.autocompleteCityArray.splice(0, this.autocompleteCityArray.length);
          for (const entry of value1.items) {
            this.autocompleteCityArray.push(entry.title);
          }
        });
      }
    });
  }


  public refreshPokemonList() {
    this.pokemonService.getPokemonsByUserId(this.userId).subscribe((data: Pokemon[]) => {
      this.pokemons = data;
      this.pokemonDataSource = new MatTableDataSource(this.pokemons);
      this.pokemonDataSource._updateChangeSubscription();
      this.loading = false;
      this.listChanged.emit();
    });
  }

  private _filterPokemon(value: string): string[] {
    const filterValue = value.toLowerCase();

    return PokemonList.filter(pokename => pokename.toLowerCase().indexOf(filterValue) === 0);
  }

  onSubmit() {
    console.warn(this.pokemonForm.value);
    const poke = new Pokemon();
    poke.userId = this.userId;
    poke.name = this.pokemonForm.value.pokemon;
    poke.city = this.pokemonForm.value.city;
    poke.pc = this.pokemonForm.value.PC;
    poke.special = this.pokemonForm.value.special;
    poke.latitude = this.latitude;
    poke.longitude = this.longitude;
    this.pokemonService.createPokemon(poke).subscribe((data: Pokemon) => {
      this.pokemons.push(data);
      this.pokemonDataSource._updateChangeSubscription();
      this.listChanged.emit(data);
      this.snackBar.open('Pokemon ajouté !', 'Ok', {
        duration: 10000,
      });
    });
    this.longitude = 0;
    this.latitude = 0;
    this.pokemonForm.reset();
    this.myForm.resetForm();
  }

  autoCompleteCallback($event: MatAutocompleteSelectedEvent) {
    // this.city = $event.address_components[0].long_name;
    console.log($event);
    this.here.getAddress($event.option.value).then(result => {
      console.log(result as Array<any>);
      this.longitude = result[0].position.lng;
      this.latitude = result[0].position.lat;
      console.log(this.latitude + ' : ' + this.longitude);
    }, error => {
      console.error(error);
    });
  }


  getImagePath(nom: string): string {
    const i = PokemonList.indexOf(nom) + 1;
    return 'assets/sprites/' + ('000' + i).slice(-3) + 'MS.png';
  }

  deletePokemon(toDelete: Pokemon) {
    this.pokemonService.deletePokemonById(toDelete.id).subscribe(value => {
      this.listChanged.emit(toDelete);
    });
    const i = this.pokemons.indexOf(toDelete);
    if (i > -1) {
      this.pokemons.splice(i, 1);
    }
    this.pokemonDataSource._updateChangeSubscription();
  }

  sortData(sort: Sort) {
    this.pokemons.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          if (a.name === b.name) {
            return this.compare(a.pc, b.pc, false);
          }
          return this.compare(a.name, b.name, isAsc);
        case 'id':
          if (a.id === b.id) {
            return this.compare(a.pc, b.pc, false);
          }
          return this.compare(a.id, b.id, isAsc);
        case 'city':
          if (a.city === b.city) {
            return this.compare(a.pc, b.pc, false);
          }
          return this.compare(a.city, b.city, isAsc);
        case 'pc':
          return this.compare(a.pc, b.pc, isAsc);
        default:
          return 0;
      }
    });
    this.pokemonDataSource._updateChangeSubscription();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openDialog(pokeDit: Pokemon): void {
    const dialogRef = this.dialog.open(PokemonEditDialogComponent, {
      width: '250px',
      data: {poke: pokeDit}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      this.pokemonService.updatePokemon(result).subscribe();
    });
  }

}



@Component({
  selector: 'app-pokemon-edit-dialog',
  templateUrl: 'pokemon-edit-dialog.html',
})
export class PokemonEditDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<PokemonEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
