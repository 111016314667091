import {Component, OnInit, ViewChild} from '@angular/core';
import {PokemonListComponent} from '../pokemon-list/pokemon-list.component';
import {Pokemon} from '../types/pokemon';
import {PokemonList} from '../types/pokemonList';
import {StompState} from '@stomp/ng2-stompjs';
import {Message} from '@stomp/stompjs';
import {PokemonService} from '../services/api/pokemon.service';

class Couple {
  public p1: Pokemon;
  public p2: Pokemon;
  public distance: number;
}


@Component({
  selector: 'app-pokemon-exchange',
  templateUrl: './pokemon-exchange.component.html',
  styleUrls: ['./pokemon-exchange.component.css']
})
export class PokemonExchangeComponent implements OnInit {

  @ViewChild('list1', {static: false}) list1: PokemonListComponent;
  @ViewChild('list2', {static: false}) list2: PokemonListComponent;

  public pokemons: Couple[] = [];
  displayedColumns: string[] = ['name1', 'city1', 'validate', 'name2', 'city2'];
  protected state = 'NOT CONNECTED';
  protected ignoreNext: number = 0;

  constructor(private pokemonService: PokemonService) {
  }

  ngOnInit() {
    // Subscribe to its stream (to listen on messages)
    // this.websocketService.stream().subscribe((message: Message) => {
    //
    //   const msg = JSON.parse(message.body);
    //   console.log(msg.refresh);
    //   if (this.ignoreNext === msg.refresh) {
    //     console.log('Ignored : ' + msg.refresh);
    //     this.ignoreNext = 0;
    //     return;
    //   }
    //   if (msg.refresh == 1) {
    //     console.log('Refresh 1');
    //     this.list1.refreshPokemonList();
    //   } else if (msg.refresh == 2) {
    //     console.log('Refresh 2');
    //     this.list2.refreshPokemonList();
    //   }
    // });
    //
    // // Subscribe to its state (to know its connected or not)
    // this.websocketService.state().subscribe((state: StompState) => {
    //   this.state = StompState[state];
    // });
  }


  getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg): number {
    return deg * (Math.PI / 180);
  }

  calculateDistance() {
    this.pokemons = [];
    const tempList1 = Object.assign([], this.list1.pokemons);
    const tempList2 = Object.assign([], this.list2.pokemons);
    while (tempList1.length > 0 && tempList2.length > 0) {
      let max: number = -1;
      let tempMax = new Couple();
      for (const p1 of tempList1) {
        for (const p2 of tempList2) {
          const dist = this.getDistanceFromLatLonInKm(p1.latitude, p1.longitude, p2.latitude, p2.longitude);
          if (dist > max) {
            max = dist;
            tempMax.p1 = p1;
            tempMax.p2 = p2;
            tempMax.distance = Math.round(dist);
          }
        }
      }
      if (tempMax.distance > 50) {
        this.pokemons.push(tempMax);
      }
      let i = tempList1.indexOf(tempMax.p1);
      if (i > -1) {
        tempList1.splice(i, 1);
      }
      i = tempList2.indexOf(tempMax.p2);
      if (i > -1) {
        tempList2.splice(i, 1);
      }
    }
  }

  getImagePath(nom: string): string {
    const i = PokemonList.indexOf(nom) + 1;
    return 'assets/sprites/' + ('000' + i).slice(-3) + 'MS.png';
  }

  onListChanged(owner: number, $event: Pokemon) {
    this.ignoreNext = owner;
    // this.websocketService.send('/topic/refresh', {refresh: owner});
    this.calculateDistance();
  }

  proceed(id1: number, id2: number) {
    this.pokemonService.proceedPokemonExchange(id1, id2).subscribe(val => {
      this.list1.refreshPokemonList();
      this.list2.refreshPokemonList();
    });
  }
}
