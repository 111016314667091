import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PokemonEditDialogComponent, PokemonListComponent} from './pokemon-list/pokemon-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule,
  MatDialogModule
} from '@angular/material';
import {PokemonExchangeComponent} from './pokemon-exchange/pokemon-exchange.component';
import {ServicesModule} from './services/services.module';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule, Routes} from '@angular/router';
import {PokemonListEditComponent} from './pokemon-list-edit/pokemon-list-edit.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './services/auth/auth-guard.service';

export const ROUTES: Routes = [{path: 'dresseur', component: PokemonExchangeComponent, canActivate: [AuthGuard]},
  {path: 'dresseur/:id', component: PokemonListEditComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent}];

@NgModule({
  declarations: [
    AppComponent,
    PokemonListComponent,
    PokemonExchangeComponent,
    PokemonListEditComponent,
    PokemonEditDialogComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatTableModule,
    MatCardModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDialogModule,
    HttpClientModule,
    ServicesModule.forRoot(),
    RouterModule.forRoot(ROUTES),
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [FormsModule, ReactiveFormsModule],
  entryComponents: [
    PokemonEditDialogComponent,
  ],
})
export class AppModule {
}
