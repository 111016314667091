import {User} from '../../types/user';

export class ChangeLoggedUser {
  public static readonly type = '[Logged User] Change Logged User';
  constructor(public user: User) {}
}

export class ResetLoggedUser {
  public static readonly type = '[Logged User] Reset Logged User';
}
