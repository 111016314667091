import {Injectable} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {UsersService} from './api/user.service';
import {LoggedUserService} from './auth/logged-user.service';
import {mergeMap, tap} from 'rxjs/operators';
import {User} from '../types/user';
import {Observable} from 'rxjs';

@Injectable()
export class InitAppService {
  constructor(
    private authService: AuthService,
    private userService: UsersService,
    private loggedUserService: LoggedUserService,
  ) {}

  public initStore() {
    return this.authService
      .isAuthenticated()
      .pipe(
        mergeMap(isAuthenticated => {
          if (isAuthenticated) {
            return this.initStoreAuthenticated();
          } else {
            return this.initStoreNotAuthenticated();
          }
        }),
      )
      .toPromise();
  }

  private initStoreAuthenticated() {
    const userId = this.authService.getUserId();
    return this.userService.refreshUser(userId).pipe(
      tap((user: User) => {
        this.loggedUserService.initLoggedUser(user); // init logged User Store
      }),
    );
  }

  private initStoreNotAuthenticated() {
    return new Observable(observer => {
      this.loggedUserService.initLoggedUser(null); // init logged User Store
      observer.next();
      observer.complete();
    });
  }
}
