import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';
import {EMPTY, Observable, Subject, throwError as observableThrowError} from 'rxjs';
import {catchError, finalize, switchMap} from 'rxjs/operators';
import {ApiError} from '../../types/errors';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  public refreshTokenInProgress = false;
  public tokenRefreshedSource = new Subject();
  public tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private injector: Injector) {
  }

  public addAuthHeader(request: HttpRequest<any>) {
    const authHeader = this.authService.getAuthorizationHeader();
    if (authHeader && request.url.includes('wazo.ovh')) {
      return request.clone({
        setHeaders: {
          Authorization: authHeader,
        },
      });
    }
    return request;
  }

  public logout() {
    this.authService.logout();
    // this.router.navigate(['login']);

    // if any modal open => dismiss the modal then redirect
  }

  public refreshToken() {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.authService.refresh().pipe(
        catchError(error => {
          this.logout();
          return observableThrowError(error);
        }),
        finalize(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
      );
    }
  }

  protected get authService(): AuthService {
    return this.injector.get(AuthService);
  }

  public needRefreshCondition(request, apiError: ApiError) {
    console.warn(apiError);
    return (
      apiError.status === 401 &&
      apiError.error !== undefined &&
      apiError.error.code !== undefined &&
      apiError.error.code === 401 &&
      (apiError.error.message === 'Expired JWT Token' || apiError.error.message === 'Invalid JWT Token')
    );
  }

  public refreshError(apiError: ApiError) {
    return (
      apiError.status === 401 &&
      apiError.error !== undefined &&
      apiError.error.code === 401 &&
      apiError.error.message === 'Bad credentials'
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('autocomplete.geocoder.api.here.com') > 0) {
      return next.handle(request);
    }

    // Handle request
    request = this.addAuthHeader(request);

    // Handle response
    return next.handle(request).pipe(
      catchError((error: ApiError) => {
        if (this.needRefreshCondition(request, error)) {
          return this.authService.isRemember().pipe(
            switchMap(remember => {
              if (remember) {
                // Try refresh Token
                return this.refreshToken().pipe(
                  switchMap(() => {
                    request = this.addAuthHeader(request);
                    return next.handle(request);
                  }),
                  catchError((err: ApiError) => {
                    // If error persist, check if it 401 or other error
                    if (this.needRefreshCondition(request, err) || this.refreshError(err)) {
                      // if 401, just logout
                      this.logout();
                      return EMPTY;
                    } else {
                      // if common user error, just forward the error
                      return observableThrowError(err);
                    }
                  }),
                );
              } else {
                // No remember -> just logout
                this.logout();
                return EMPTY;
              }
            }),
          );
        }
        // Common user error, forward the error
        return observableThrowError(error);
      }),
    );
  }

}
