import {Injectable} from '@angular/core';
import {LoggedUserState} from '../states/logged-user.state';
import {Observable} from 'rxjs';
import {User} from '../../types/user';
import {ChangeLoggedUser, ResetLoggedUser} from '../states/logged-user.actions';
import {Select, Store} from '@ngxs/store';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoggedUserService {
  @Select(LoggedUserState.user) public loggedUser$: Observable<User | null>;

  constructor(private store: Store) {}

  public initLoggedUser(initialValue: User) {
    this.store.dispatch(new ChangeLoggedUser(initialValue));
  }

  public instant() {
    return this.loggedUser$.pipe(take(1));
  }

  public setLoggedUser(user: User) {
    this.store.dispatch(new ChangeLoggedUser(user));
  }

  public resetLoggedUser() {
    this.store.dispatch(new ResetLoggedUser());
  }

  public getLoggedUser(): Observable<User | null> {
    return this.loggedUser$;
  }
}
