import {Injectable} from '@angular/core';
import {LoggedUserService} from '../auth/logged-user.service';
import {HttpClient} from '@angular/common/http';
import {User} from '../../types/user';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public static ENDPOINT = '/users';

  public loggedUser: any = {};

  constructor(private http: HttpClient, private loggedUserService: LoggedUserService) {}

  public refreshUser(userId: string): Observable<User> {
    const editUrl = this.getAPiUrl() + '/' + userId;
    return this.http.get(editUrl).pipe(
      tap((response: User) => {
        this.loggedUserService.setLoggedUser(response);
      }),
    );
  }

  private getAPiUrl() {
    return environment.settings.api + UsersService.ENDPOINT;
  }

}
