import {Component} from '@angular/core';
import {AuthService} from './services/auth/auth.service';
import {Router} from '@angular/router';
import {LoggedUserService} from './services/auth/logged-user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'PokemonExchange2';
  opened = false;


  constructor(private authService: AuthService, private router: Router, private loggedUserService: LoggedUserService) {
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
    this.opened = false;
  }

  mesPokemons() {
    this.loggedUserService.getLoggedUser().subscribe(user => {
      if (user) {
        this.router.navigate(['/dresseur', user.id]);
      } else {
        this.router.navigate(['/login']);
      }
    });
    this.opened = false;
  }

}
