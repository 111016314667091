import {Injectable} from '@angular/core';
import {AuthToken} from './auth-token';
import {Observable, of as observableOf} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {LoggedUserService} from './logged-user.service';
import {User} from '../../types/user';
import {UsersService} from '../api/user.service';

export interface LoginPayload {
  accessToken: string;
  refreshToken: string;
  userId: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(protected token: AuthToken,
              protected http: HttpClient,
              protected usersService: UsersService,
              protected loggedUserService: LoggedUserService) {
  }

  public getAuthorizationHeader(): string {
    const token = this.token.getAccessToken();
    if (token && token !== 'undefined') {
      return 'Bearer ' + token;
    }
    return null;
  }

  public getToken(): Observable<AuthToken> {
    return observableOf(this.token);
  }

  public isAuthenticated(): Observable<boolean> {
    return this.getToken().pipe(
      map(token => {
        return token.isAuthenticated();
      }),
    );
  }

  public refresh(): Observable<any> {
    const url = this.getAPiUrl() + '/refresh';
    return this.http.post(url, {refresh_token: this.token.getRefreshToken()}, {withCredentials: true}).pipe(
      tap((payload: LoginPayload) => {
        this.token.setFromPayload(Object.assign({remember: this.token.isRemember()}, payload));
      }),
    );
  }

  public isRemember(): Observable<boolean> {
    return this.getToken().pipe(
      map(token => {
        return token.isRemember();
      }),
    );
  }

  public login(username: string, password: string, remember?: boolean): Observable<User> {
    const url = this.getAPiUrl() + '/authenticate';
    return this.http.post(url, {username, password}, {withCredentials: true}).pipe(
      mergeMap((payload: LoginPayload) => {
        this.token.setFromPayload(Object.assign({remember}, payload));
        return this.usersService.refreshUser(payload.userId);
      }),
      tap((user: User) => {
        this.loggedUserService.setLoggedUser(user);
      }),
    );
  }

  public logout() {
    this.token.clear();
    this.loggedUserService.resetLoggedUser();
  }

  public getUserId(): string | null {
    if (this.token.getData().UserId) {
      return this.token.getData().UserId;
    }
    return null;
  }

  private getAPiUrl() {
    return environment.settings.api;
  }
}
