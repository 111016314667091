import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../services/auth/auth.service';
import {tap} from 'rxjs/operators';
import {User} from '../types/user';
import {Router} from '@angular/router';

interface LoginForm extends FormGroup {
  value: {
    username: string;
    password: string;
    remember: boolean;
  };
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  public submitted = false;
  public loginForm: LoginForm;
  public user: User | null = null;
  public errors: string[] = [];

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
      remember: [true],
    });
  }

  login() {
    console.warn('logging in...' + this.loginForm.valid);
    if (!this.loginForm.valid || this.submitted) {
      return;
    }
    console.warn('...');
    this.submitted = true;

    this.authService
      .login(this.loginForm.value.username, this.loginForm.value.password, this.loginForm.value.remember)
      .pipe(
        tap((user: User) => {
          this.user = user;
        })
      ).subscribe(user => {
        this.errors = ['Connecté !'];
        this.router.navigate(['/dresseur']);
    },
      err => {
        this.submitted = false;
        this.errors = ['Combinaison Email/Mot de passe incorrecte.'];
      });
  }

}
